// import React from 'react';
// import { useNavigate } from 'react-router-dom';
// import './PaymentPopup.css';
// import QRImage from './QR.png'; // Make sure the image path is correct

// const PaymentPopup = ({ orderId, subtotal, bankDetails, onClose }) => {
//   const navigate = useNavigate();

//   const handleClose = () => {
//     onClose();
//     navigate('/'); // Navigate to the home page
//   };

//   return (
//     <div className="popup-overlay">
//       <div className="popup-content">
//         <h2>Payment Information</h2>
//         <p>Order ID: {orderId}</p>
//         <p>TOTAL: LKR {subtotal}</p>
//         <p><b>*IGNORE IF SELECTED CASH ON DELIVERY - COD*</b></p>
//         <h3>Bank Details:</h3>
//         <p>Bank Name: {bankDetails.bank_name}</p>
//         <p>Bank Branch: {bankDetails.bank_branch}</p>
//         <p>Account Name: {bankDetails.account_name}</p>
//         <p>Account Number: {bankDetails.account_number}</p>
//         <p>Please pay the amount and WhatsApp the receipt to the following number if have selected <b>Bank Deposite Option </b>: {bankDetails.whatsapp_number}</p>
//         <div style={{ textAlign: 'center' }}>
//           <img src={QRImage} alt="QR Code" style={{ maxWidth: '100%', height: 'auto' }} />
//         </div>
//         <p>Thank you! Come back again.</p>
//         <button onClick={handleClose}>Close</button>
//       </div>
//     </div>
//   );
// };

// export default PaymentPopup;


import React from 'react';
import { useNavigate } from 'react-router-dom';
import './PaymentPopup.css';
import QRImage from './QR.png'; // Make sure the image path is correct

const PaymentPopup = ({ orderId, subtotal, bankDetails, onClose }) => {
  const navigate = useNavigate();

  const handleClose = () => {
    onClose();
    navigate('/'); // Navigate to the home page
  };

  const hasBankDetails =
    bankDetails.bank_name || 
    bankDetails.bank_branch || 
    bankDetails.account_name || 
    bankDetails.account_number;

  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <h2>Payment Information</h2>
        <p>Order ID: {orderId}</p>
        <p>TOTAL: LKR {subtotal}</p>
        {hasBankDetails ? (
          <>
            
            <h3>Bank Details:</h3>
            {bankDetails.bank_name && <p>Bank Name: {bankDetails.bank_name}</p>}
            {bankDetails.bank_branch && <p>Bank Branch: {bankDetails.bank_branch}</p>}
            {bankDetails.account_name && <p>Account Name: {bankDetails.account_name}</p>}
            {bankDetails.account_number && <p>Account Number: {bankDetails.account_number}</p>}
            <p>
              Please pay the amount and WhatsApp the receipt to the following number {' '}
              <b></b>: {bankDetails.whatsapp_number}
            </p>
          </>
        ) : (
          <p><b>Please pay on Delivery</b></p>
        )}
        <div style={{ textAlign: 'center' }}>
          <img src={QRImage} alt="QR Code" style={{ maxWidth: '100%', height: 'auto' }} />
        </div>
        <p>Thank you! Come back again.</p>
        <button onClick={handleClose}>Close</button>
      </div>
    </div>
  );
};

export default PaymentPopup;
